<template>
  <div class="decision-manual-inspection">
    <Container>
      <Card padding="large" variant="border">
        <b-row>
          <b-col cols="12" sm="auto" class="text-center">
            <ProcessingSVG class="icon h1" />
          </b-col>
          <b-col>
            <Margins>
              <h1 class="h3 text-normal" v-html="manualInspectionText" />
              <p class="text-small">{{ $t('MANUAL_INSPECTION_INFO') }}</p>
              <p class="text-small">{{ $t('MANUAL_INSPECTION_INFO_2') }}</p>
            </Margins>
          </b-col>
        </b-row>

        <Separator size="large" />
        <div class="text-center">
          <Button @click="window.location.reload(true)">{{
            $t('REFRESH')
          }}</Button>
        </div>
      </Card>
    </Container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import {
  GET_APPLICATION_STATUS,
  SET_APPLICATION_STATUS,
  CREATE_ACCOUNT,
} from '@/types';
import { Container, Card, Margins, Separator, Button } from '@/components';
import ProcessingSVG from '@/assets/images/processing.svg';
import { constants } from '@/mixins';

export default {
  name: 'ManualInspection',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Separator,
    Button,
    ProcessingSVG,
  },
  data() {
    return {
      polling: null,
    };
  },
  mounted() {
    this.GET_APPLICATION_STATUS();
    this.pollStatus();

    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case SET_APPLICATION_STATUS:
          {
            const { decision } = mutation.payload;
            const { APPROVED, DENIED } = this.DECISION_TYPES;

            switch (decision) {
              case APPROVED:
                this.CREATE_ACCOUNT();
                break;
              case DENIED:
                this.$router.push({ name: this.ROUTES.DECISION_DENIED.name });
                break;
              default:
            }
          }
          break;
        default:
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    ...mapActions([GET_APPLICATION_STATUS, CREATE_ACCOUNT]),
    pollStatus() {
      this.polling = setInterval(() => {
        this.GET_APPLICATION_STATUS();
      }, 15000);
    },
  },
  computed: {
    manualInspectionText() {
      const phoneNumber = process.env.VUE_APP_RESURS_BANK_PHONE_NUMBER;

      return this.$t('MANUAL_INSPECTION', [
        `<a href="tel:${phoneNumber}">${phoneNumber}</a>`,
      ]);
    },
  },
};
</script>
